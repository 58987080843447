<template>
	<ViewLayout className="howto">
		<template v-slot:body>
			<h1>{{ $store.strings.howto_title }}</h1>
			<p v-if="$store.strings.howto_intro">{{ $store.strings.howto_intro }}</p>
			<MediaBox v-if="$store.strings.howto_tutorial" v-bind:content="$store.strings.howto_tutorial" />

			<h2 v-if="$store.strings.howto_notes && $store.strings.howto_notesTitle">{{ $store.strings.howto_notesTitle }}</h2>
			<ul v-if="$store.strings.howto_notes">
				<li v-for="note of $store.strings.howto_notes.split('\n')" :key="note">{{ note }}</li>
			</ul>
			<div class="spacer"></div>
		</template>
		<template v-slot:footer>
			<router-link to="cam-front">
				<button class="uk-button uk-button-primary uk-button-large blue-action-button" onclick="_paq.push(['trackEvent', 'how_to', 'next']);">
					<i uk-icon="icon: camera"></i>{{ $store.strings.howto_next }}
				</button>
			</router-link>
			<router-link to="/cistern" class="go-back-action-button" onclick="_paq.push(['trackEvent', 'how_to', 'back']);">
				{{ $store.strings.general_back }}
			</router-link>
		</template>
	</ViewLayout>
</template>

<script>
import ViewLayout from '@/components/ViewLayout.vue'
import MediaBox from '@/components/MediaBox.vue'

export default {
	name: 'HowTo',
	components: {
		ViewLayout,
		MediaBox,
	},
}
</script>
